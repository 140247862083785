<template>
    <div>
        <div class="my-10 pb-10 d-flex justify-content-between">
            <h1 class="text-center">
                Detalles de grupo de orden
                <strong class="text-primary"> #{{ itemComponent.id }} </strong>
            </h1>
            <div class="d-flex align-items-center">
                <div>
                    <vs-button circle icon floating @click="getBaseItem">
                        <reload-icon />
                    </vs-button>
                </div>
            </div>
        </div>
        <div v-if="hasLoadedBase" class="my-4 d-flex flex-wrap">
            <div class="col-12 col-md-6 d-flex flex-column my-2">
                <strong>Fecha de Fragmentación </strong>
                <span class="mt-2 ml-2">
                    {{ itemComponent.createdAt | date }}
                </span>
            </div>
            <div class="col-12 col-md-6 d-flex flex-column my-2">
                <strong>Fecha de última actualización </strong>
                <span class="mt-2 ml-2">
                    {{ itemComponent.updatedAt | date }}
                </span>
            </div>
            <div class="col-12 col-md-6 d-flex flex-column my-2">
                <strong>Estado de pago del grupo de ordenes </strong>
                <span
                    class="mt-2 ml-2"
                    :class="
                        $data.$paymentStatusGroupMessageClass[
                            itemComponent.paymentStatusGroup
                        ]
                    "
                >
                    {{
                        $data.$paymentStatusesGroupCodes[
                            itemComponent.paymentStatusGroup
                        ]
                    }}
                </span>
            </div>
            <div class="col-12 col-md-6 d-flex flex-column my-2">
                <strong>Estado del grupo de ordenes </strong>
                <span
                    class="mt-2 ml-2"
                    :class="
                        $data.$deliveryStatusesGroupMessageClass[
                            itemComponent.deliveryStatusGroup
                        ]
                    "
                >
                    {{
                        $data.$deliveryStatusesGroupCodes[
                            itemComponent.deliveryStatusGroup
                        ]
                    }}
                </span>
            </div>

            <div class="d-flex overflow-x-auto mt-4 col-12">
                <div
                    v-for="column in itemComponent.orders"
                    :key="column.id"
                    class="mr-1"
                >
                    <div
                        class="border rounded border-secondary w-72 px-2 pt-3 h-100"
                    >
                        <router-link
                            :to="`/app/orders/detail/${column.id}`"
                            class="btn-a p-0"
                        >
                            <h3>
                                Orden #
                                {{ column.id }}
                            </h3>
                        </router-link>

                        <div class="d-flex justify-content-center flex-column">
                            <span class="my-1">
                                <strong>Proveedor : </strong>
                                <span class="text-capitalize">
                                    {{
                                        column.supplyMerchant.merchantType
                                            | merchantInfo("icon", "type")
                                    }}
                                    {{ column.supplyMerchant.name }}
                                </span>
                            </span>
                            <span class="my-1">
                                <strong>Dirección proveedor : </strong>
                                <span>
                                    {{
                                        column.supplyMerchant.address
                                            | address
                                            | cut(28)
                                    }}
                                    {{
                                        `${
                                            column.supplyMerchant
                                                .addressComplement
                                        },${
                                            column.supplyMerchant.neighborhood
                                                ? `barrio ${column.supplyMerchant.neighborhood} de `
                                                : ""
                                        }${column.supplyMerchant.city}`.trim()
                                    }}
                                </span>
                            </span>

                            <span class="my-1">
                                <strong> Estado : </strong>
                                <span
                                    :class="
                                        $data.$deliveryStatusesMessageClass[
                                            column.deliveryStatus
                                        ]
                                    "
                                >
                                    {{
                                        $data.$deliveryStatusesCodes[
                                            column.deliveryStatus
                                        ]
                                    }}
                                </span>
                            </span>

                            <span>
                                <strong> Estado de pago : </strong>
                                <span
                                    :class="
                                        $data.$deliveryStatusesMessageClass[
                                            column.deliveryStatus
                                        ]
                                    "
                                >
                                    {{
                                        $data.$deliveryStatusesCodes[
                                            column.deliveryStatus
                                        ]
                                    }}
                                </span>
                            </span>

                            <span class="my-1">
                                <strong> 🗓 Actualización : </strong>
                                <span> {{ column.updatedAt | date }} </span>
                            </span>

                            <span class="my-1">
                                <strong> Total: </strong>
                                <span> {{ column.total | money }} </span>
                            </span>

                            <span class="my-1">
                                <strong> Precio de productos: </strong>
                                <span>
                                    {{
                                        column.referencesValueWithDiscount
                                            | money
                                    }}
                                </span>
                            </span>

                            <span class="mt-1">
                                <strong> Productos: </strong>
                                <span>{{ column.references.length }}</span>
                            </span>

                            <reference-table-item-2
                                v-for="row in column.references"
                                :key="row.id"
                                :reference="row"
                                class="my-2"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import {
    deliveryStatusesCodes,
    deliveryStatusesMessageClass
} from "@/common/fields/delivery-status-codes";
import {
    deliveryStatusesGroupCodes,
    deliveryStatusesGroupMessageClass
} from "@/common/fields/delivery-status-group-codes";
import {
    paymentStatusesCodes,
    paymentStatusesMessageClass
} from "@/common/fields/payment-status-codes";
import {
    paymentStatusGroupMessageClass,
    paymentStatusesGroupCodes
} from "@/common/fields/payment-status-group-codes";

import ReloadIcon from "@/components/icons/ReloadIcon.vue";
import ReferenceTableItem2 from "@/components/references/ReferenceTableItem2.vue";

export default {
    name: "OrdersGroupsIndex",
    components: { ReferenceTableItem2, ReloadIcon },
    data: () => ({
        id: null,
        hasLoadedBase: false,
        itemComponent: {
            id: 1,
            type: "store-store",
            deliveryStatusGroup: "nod",
            paymentStatusGroup: "nop",
            createdAt: "2023-06-24T23:25:31.338Z",
            updatedAt: "2023-06-24T23:25:31.338Z",
            orders: []
        },
        $deliveryStatusesGroupCodes: deliveryStatusesGroupCodes,
        $deliveryStatusesGroupMessageClass: deliveryStatusesGroupMessageClass,
        $paymentStatusGroupMessageClass: paymentStatusGroupMessageClass,
        $paymentStatusesGroupCodes: paymentStatusesGroupCodes,
        $paymentStatusesCodes: paymentStatusesCodes,
        $paymentStatusesMessageClass: paymentStatusesMessageClass,
        $deliveryStatusesCodes: deliveryStatusesCodes,
        $deliveryStatusesMessageClass: deliveryStatusesMessageClass
    }),
    computed: {
        ...mapGetters("control", ["backgroundColor"])
    },
    async mounted() {
        const verifyPageScope = this.$ability.verifyPageScope.bind(this);
        if ("id" in this.$route.params) {
            if (
                !(await verifyPageScope("orderGroup:Get", "/app/orders-groups"))
            )
                return;
            this.id = Number(this.$route.params.id);
            return this.getBaseItem();
        }
    },
    methods: {
        ...mapActions("orders", ["getOrderGroupById"]),
        async getBaseItem() {
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "square" });
            try {
                this.itemComponent = await this.getOrderGroupById({
                    id: this.id
                });
                this.hasLoadedBase = true;
            } catch (error) {
                this.loaderInstance.close();
                await this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
                if (error.statusCode === 404) {
                    this.$router.push("/app/orders");
                }
            } finally {
                this.isLoading = false;
                this.loaderInstance.close();
            }
        }
    }
};
</script>
